import { Box, Button, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh'
import { MapRotationTable, PlayersTable, ServerInfoTable, ServerModsTable } from './Tables/table';
import * as api from '../api/calls';
import md5 from 'md5';
import RconOptions2 from './rconOptions';
import { Link } from 'gatsby';
import { CommandHistoryTable } from './Tables/CommandHistory';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    maxWidth: 500,
    fontSize: '0.5rem'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  compact: {
    padding: '0',
    margin: '0',
    fontSize: '1rem'
  },
  builder: {
    paddingTop: 10
  },
  note: {
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontWeight: 10
  },
  hiddenTxt: {

  }
}));


function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function isMD5(str) {
  const regx = RegExp(/^[a-f0-9]{32}$/i)
  const res = regx.test(str)
  return res
}

function RconBuilder({ server }) {

  const classes = useStyles()

  //Default IP
  let initIP = '0.0.0.0'
  try {
    initIP = server.server.ip
  } catch (e) {
    console.log(e)
  }

  const isPreFilled = !!(server && server.server && server.server.ip && server.server.rconpass)


  const [connectState, setConnect] = useState('Disconnected')
  const [isProcessing, setProcessing] = useState(false)
  const [ip, setIP] = useState(initIP)
  const [port, setPort] = useState(server && server.server && 9100)
  const [pwd, setPwd] = useState(server && server.server && server.server.rconpass)
  const [playerList, setPlayers] = useState([])
  const [serverInfo, setServerInfo] = useState([])
  const [mapRotation, setMapRotation] = useState([])
  const [serverMods, setServerMods] = useState([])
  const [errorMsg, setErrorMsg] = useState()
  const [commandHistory, setCommandHistory] = useState([])
  const [rconLists, setRconLists] = useState()
  const [mapsList, setMapsList] = useState()
  const [banList, setBanList] = useState()
  const [modsList, setModsList] = useState()
  const [gameModesList, setGameModesList] = useState()
  const [isLoggedIn, setIsLoggedIn] = useState(true)

  useEffect(() => {



    if (isPreFilled) {
      onSubmit()
    } else {
      checkLoggedIn()
    }

    //Check prefilled

  }, [])

  const checkLoggedIn = () => {
    const userDetails = window.localStorage.getItem('userDetails')
    const isLoggedIn = !!userDetails
    setIsLoggedIn(isLoggedIn)
  }


  const refreshServer = () => {

    let md5pwd = pwd
    //Check MD5
    if (!isMD5(pwd)) {
      md5pwd = md5(pwd)
      setPwd(md5pwd)
    // console.log(`Converted ${pwd} to MD5 hash: ${md5pwd}`)
    }

    //Get Server Details
  // console.log('Connecting: ', ip, port, md5pwd)
    api.rconConnect(ip, port, md5pwd)
      .then(data => {

        //Get PlayerList
      // console.log('Connected:', data)
        try {
          const playerListNoErrors = data.data.playerList.filter(a => a.Successful)
          let sortedPlayerList = playerListNoErrors.sort((a, b) => {
            const aA = a.PlayerInfo.KDA && parseInt(a.PlayerInfo.KDA.split('/')[2]) || 0
            const bA = b.PlayerInfo.KDA && parseInt(b.PlayerInfo.KDA.split('/')[2]) || 0
            return (bA - aA)
          })
          sortedPlayerList = sortedPlayerList.sort((a, b) => parseInt(b.PlayerInfo.TeamId) - parseInt(a.PlayerInfo.TeamId))
          setPlayers(sortedPlayerList)

          //Get ServerInfo
          let serverInfo = data.data.serverInfo.ServerInfo
          // console.log(serverInfo)
          api.getAllServers().then(serverList => {
            // console.log(serverList)
            const thisServer = serverList.find(x => (x.name == serverInfo.ServerName && x.ip == ip))
            if (thisServer) {
              // console.log(thisServer)
              serverInfo.MapLabel = thisServer.mapLabel
              serverInfo.GameMode = thisServer.gameMode
            }
            setServerInfo(serverInfo)
            setConnect('Connected')
            setProcessing(false)
          })

        } catch (e) {
        // console.log('RCON Connect Error: ', e)
          setConnect('Error')
          setErrorMsg(e)
          setProcessing(false)
        }
      })

  }

  //Initial Connection
  const onSubmit = (event) => {
    if (event) event.preventDefault()


    //Connect
    refreshServer()

    //Get Maps
    api.getMapsModio().then(maps => {
      try {
        let mapRotation = []

        api.rconSendCommands(ip, port, pwd, ['MapList']).then(res => {
          let myMapRotation = res.data[0].MapList

        // console.log('Got MapList:', myMapRotation, maps)
          myMapRotation = myMapRotation.map(m => {
            const mapMatch = maps.find(x => x.id == m.MapId)
            const mapName = mapMatch ? mapMatch.name : m.MapId
            const mapLogo = mapMatch ? mapMatch.logo : undefined

            return { ...m, MapName: mapName, logo:mapLogo }
          });
          mapRotation = myMapRotation

          setMapRotation(mapRotation)
          setMapsList(maps)

        }).catch(e => console.log('Error: ', e))


      } catch (e) {
        console.log('Error: ', e)
      }
    })

   //Get Mods
   api.getModsModio().then(mods => {
    try {
      let serverMods = []

      api.rconSendCommands(ip, port, pwd, ['UGCModList']).then(res => {
        let myServerMods = res.data[0].ModList

      // console.log('Got ModList:', myServerMods, mods)
        myServerMods = myServerMods.map(m => {
          const modMatch = mods.find(x => x.id == m)
          const modName = modMatch ? modMatch.name : m

          return { ...m, ModName: modName}
        });
        serverMods = myServerMods

        setServerMods(serverMods)
        setModsList(mods)

      }).catch(e => console.log('Error: ', e))

    } catch (e) {
      console.log('Error: ', e)
    }
  })


    //Get Banlist
    if (!banList) {
      api.rconSendCommands(ip, port, pwd, ['BanList']).then(res => {
        const myBanList = res.data && res.data[0] && res.data[0].BanList || []
      // console.log('Got Banlist:', myBanList)
        setBanList(myBanList)
      }).catch(e => console.log('Error: ', e))
    }
    setProcessing(true)


    //Get RCON Lists
    api.getRCONLists().then(res => {
      setRconLists(res)
    }).catch(e => console.log('Error: ', e))

    //Get RCON Lists
    api.getGameModesModio().then(res => {
      setGameModesList(res)
    }).catch(e => console.log('Error: ', e))
  }


  const onExecute = (commands) => {
    setProcessing(true)
    const userDetails = window.localStorage.getItem('userDetails')
    api.rconSendCommands(ip, port, pwd, commands, userDetails)
      .catch(e => console.log('Error: ', e))
      .then(x => {
        const res = x.data && x.data.length > 0 ? x.data[0] : { Successful: false }
        console.log(res)
        const commandHistoryObject = {
          ...res,
          ExecTime: new Date().toLocaleString(),
          Command: commands.join(', '),
        }
        setCommandHistory([...commandHistory, commandHistoryObject])
        setProcessing(false)
        refreshServer()
      })
  }

  const handleIP = (e) => {
    setIP(e.target.value)
  }

  const handlePort = (e) => {
    setPort(e.target.value)
  }

  const handlePwd = (e) => {
    setPwd(e.target.value)
  }

  const playersTxt = playerList.length > 0 ? playerList.map(p => p.Username).join(', ') : "No players."
  const serverInfoTxt = serverInfo.ServerName + ' - ' + serverInfo.MapLabel + ' - ' + serverInfo.GameMode + ' - ' + serverInfo.PlayerCount + ' players'

  let optionsPlayerList = playerList.map(p => {
    const res = {
      "Username": p.PlayerInfo.PlayerName,
      "UniqueID": p.PlayerInfo.UniqueId
    }
    return res
  })

  if (!optionsPlayerList || optionsPlayerList.length < 1) optionsPlayerList = [{ "Username": "", "UniqueID": "" }]

  const getHeader = () => {
    switch (connectState) {
      case "Disconnected":
        return <Button variant="contained" color="secondary" type="submit">{isProcessing ? 'Connecting...' : 'Connect'}</Button>
      case "Error":
        return (<Box>
          <Box>
            {`Error, sorry. Please check your IP, port and password are correct.`}
          </Box>
          <Box>{`Please Refresh the Page`}</Box>
        </Box>)
      case "Connected":
        return <Box>
         
          <ServerInfoTable serverInfo={serverInfo}/>
          <ServerModsTable serverMods={serverMods}></ServerModsTable>
          <MapRotationTable mapRotation={mapRotation} currentMap={serverInfo.MapName} currentGameMode={serverInfo.GameMode}></MapRotationTable>

          {playerList.length > 0 && <PlayersTable playerList={playerList}></PlayersTable>}

          {rconLists && banList && mapsList && modsList && gameModesList &&
            <RconOptions2 
            isProcessing={isProcessing} 
            onExecute={onExecute} 
            serverInfo={serverInfo} 
            banList={banList} 
            rconLists={rconLists} 
            playerList={optionsPlayerList} 
            mapsList={mapsList}
            modsList={modsList}
            gameModesList={gameModesList}
            />}

          <CommandHistoryTable commandHistory={commandHistory} />
          
        </Box>
    }
  }

  const headerButton = getHeader()

  return (
    <Box>
      {/* Change to (!isLoggedIn) */}
      {(!isLoggedIn) ?
        <Box>
          <Typography>
            This RCON tool works best with Horde Servers. You may have errors if you did not purchase your server from this site.
          </Typography>
          <Link to="/buy">
            <Button variant="contained" color="secondary">Buy Server</Button>
          </Link>
          <Typography>
            Please Sign Up/Log In (top right) and Refresh the page.
          </Typography>
        </Box> :
        <form noValidate autoComplete={isPreFilled ? "off" : 'on'} onSubmit={onSubmit}>
          <div>
            <Box>
              <TextField type="ip" name="ipadd" onChange={handleIP} required id="ipaddr" label="IP Address" autoComplete={isPreFilled ? "off" : 'on'} defaultValue={ip} />
              <TextField
                required
                name="pwd"
                id="newpass"
                label="Password"
                onChange={handlePwd}
                type="password"
                placeholder="Password"
                className={classes.hiddenTxt}
                defaultValue={pwd}
                autoComplete={isPreFilled ? "off" : 'on'}
              />
              <TextField name="portno" onChange={handlePort} required id="portno" label="Port" autoComplete={isPreFilled ? "off" : 'on'} defaultValue={port} />
              <Box pt={2} pl={2} display={"inline-block"}><Button variant="contained" onClick={onSubmit}>{isProcessing ? 'Refreshing...' : 'Refresh'}<RefreshIcon /></Button></Box>
            </Box>
            <Box pt={3} mx="auto" >
              <div>
                {headerButton}
              </div>
            </Box>
          </div>

        </form>
  }
        </Box>
  )
}

      export default RconBuilder