import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  note: {
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontWeight: 10
  },
  headerRow: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontWeight: 10,
    lineHeight: 1.5,
    height: 25,
  }
}));

export const CommandHistoryTable = ({ commandHistory }) => {

  const classes = useStyles();

// console.log(commandHistory)

  return (

    <Box pt={3}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow className={classes.headerRow}>
            <TableCell>Command History</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Result</TableCell>
            </TableRow>
          </TableHead>

          <TableBody >
            {commandHistory.map((command, i) =>
              <TableRow key={`${command}${i}`}>
                <TableCell>{command.Command}</TableCell>
                <TableCell>{command.ExecTime}</TableCell>
                <TableCell>{command.Successful ? 'Success' : 'Failed'}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
};
