import { IconButton, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { navigate } from "gatsby";
import React from 'react';
import Layout from '../components/layout';
import RconBuilder from '../components/rconConnect';
import SEO from '../components/seo';

const SecondPage = ({ location }) => {

  const handleBack = () => {
    navigate("/")
  }

  const pageTitle = "Pavlov Rcon Tool"
  const pageDesc = "Browser-based Pavlov Remote Connection Tool for Custom Servers"

  return (
  <Layout pageTitle={pageTitle}>
    <SEO desc={pageDesc} title={pageTitle} />
      <IconButton
      
      onClick={handleBack}>
        <ArrowBackIcon/>
      </IconButton>
      <Typography variant="h3" style={{ color: "white" }}>
          Rcon
          </Typography>
    <RconBuilder server={location.state} />
  </Layout>
)}

export default SecondPage
