import { Box, Button, makeStyles, Tab, Tabs, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CommandRadio, SingleSelect } from './formControls'
import FormControl from '@material-ui/core/FormControl';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    // maxWidth: 500,
    fontSize: '0.5rem',
    paddingLeft: 10,
  },
  commandBox: {
    margin: theme.spacing(1),
    // minWidth: 120,
    // maxWidth: 500,
    fontSize: '0.5rem',
    paddingLeft: 10,
    width: '100%',
  },
  arguments: {
    display: 'inline-block',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  compact: {
    padding: '0',
    margin: '0',
    fontSize: '1rem'
  },
  builder: {
    paddingTop: 10
  },
  note: {
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontWeight: 10
  },
  hiddenTxt: {

  },
  commandCheckBox: {
    // textAlign: 'center',
  },
  commandCheck:{
    fontSize: '1.1rem',
    padding: '0.5rem',
    color: 'rgba(255, 255, 255, 0.7)'
  },
  cmdBox: {
    width: '100%',
    display: 'flex',
  },
  headerRow: {
    backgroundColor: 'rgba(0,0,0,0.3)'
},
tabs:{
  backgroundColor: 'rgba(0,0,0,0.5)'
},
executeButton:{
  width:'10rem',
}
}));

export default function RconOptions2({ rconLists, serverInfo, onExecute, isProcessing, banList, playerList, mapsList, modsList, gameModesList }) {

  const classes = useStyles()
  const { itemList, skinList, vehicleList, rconDetail, defaultGameModes } = rconLists
  const commandList = rconDetail.map(c => c.cmd)
  const commandNames = rconDetail.map(c => c.name || c.cmd.split(" ")[0])
  const teamList = [{ TeamId: 1, Color: "Red" }, { TeamId: 2, Color: "Blue" }]
  const gameModesAllList = [...defaultGameModes, ...gameModesList]

  const [selectedCommandDetail, setSelectedCommandDetail] = useState(rconDetail[0])
  const [selectedCommandBase, setSelectedCommandBase] = useState(rconDetail[0].cmd.split(" ")[0])
  const [selectedCommandArgs, setSelectedCommandArgs] = useState([])


  const [commandName, setCommandName] = useState(commandNames[0])
  const [commandArgMap, setCommandArgMap] = useState(commandList[0].split(" ").slice(1))
  const [commandArgs, setCommandArgs] = useState([])
  const [finalCommand, setFinalCommand] = useState()
  const [isError, setIsError] = useState(false)


  //evals
  const mapEval = {
    list:mapsList.map(m=>m.name),
    allowCustom:true,
    eval:mapsList.map(m=>m.id)
  }

  const modEval = {
    list:modsList.map(m=>m.name),
    allowCustom:true,
    eval:modsList.map(m=>m.id)
  }

  const teamEval = {
    eval:teamList.map(t=>t.TeamId),
    list:teamList.map(t=>t.Color)
  }

  const playerEval = {list:playerList.map(p=>p.Username), eval:playerList.map(p=>p.UniqueID), allowCustom:true}
  const skinEval = {list:skinList, allowCustom:true}
  const itemEval = {list:itemList, allowCustom:true}
  const vehicleEval = {list:vehicleList, allowCustom:true}

  const commandArgEval = {
    UniqueID:playerEval,
    UniqueId:playerEval,
    MapID: mapEval,
    MapId: mapEval,
    GameMode:{
      list:gameModesAllList.map(g=>g.name), 
      eval:gameModesAllList.map(g=>g.id), 
      allowCustom:true
    },
    ItemID:itemEval,
    ItemId:itemEval,
    SkinID:skinEval,
    SkinId:skinEval,
    VehicleID: vehicleEval,
    TeamID: teamEval,
    TeamId: teamEval,
    ModUGCId: modEval,
    MaxPlayerNumber:{
      list: [2,5,8,10,15],
      allowCustom: true,
      validator: (e) => /^\d+$/gm.test(e),
      helperText: 'Number only'
    },
    CashAmt: {
      list: Array.from(Array(10), (x, i) => (i + 1) * 1000), 
      allowCustom: true,
      validator: (e) => /^\d+$/gm.test(e),
      helperText: 'Number only'
    },
    TrueFalse:{list:['True','False']},
    KarmaAmt: { 
      list: Array.from(Array(10), (x, i) => (i + 1) * 1000), 
      allowCustom: true,
      validator: (e) => /^\d+$/gm.test(e),
      helperText: 'Number only'
    },
    RoleID: {list:[], allowCustom:true},
    PinNumber:{
      list:['1234'],
      allowCustom:true,
      validator: (e) => /^\d+$/gm.test(e),
      helperText: 'Number only'
    },
    DamageAmount:{
      list: Array.from(Array(10), (x, i) => (i + 1) * 10), 
      allowCustom:true,
      validator: (e) => /^-?\d*\.?\d+$/gm.test(e),
      helperText: 'Number only'
    },
    AmmoType:{
      list: [0,1,2,3,4,5]
    },
    Player: {
      list: [...playerEval.list, ...teamEval.list, 'All'], 
      eval: [...playerEval.eval, ...teamEval.eval, 'All'], 
      allowCustom:true
    },
    Boolean: {
      list: ['True','False']
    },
    Object: {
      list: ['All', 'Bodies', 'Items', 'Vehicles']
    },
    Team: {
      list: ['RedTeam', 'BlueTeam']
    },
    DefaultArg:{
      list:[], 
      allowCustom:true
    }
  }

  commandArgEval.TimeInSeconds = commandArgEval.DamageAmount
  commandArgEval.MoveUniqueID = commandArgEval.UniqueID
  commandArgEval.ToUniqueID = commandArgEval.UniqueID
  

  //state updates
  useEffect(() => {
    handleCommandClick(commandNames[0])
  },[])

  useEffect(()=>{
    getCommandArgMap(commandName)
  },[commandName])

  useEffect(()=>{
    selectDefaults(commandName)
  },[commandArgMap])

  useEffect(()=>{
    makeFinalCommand()
  },[commandArgs])


  //functions
  const handleExecute = () => {
    onExecute([finalCommand])
  }

  const makeFinalCommand = () =>{
    let thisFinalCommand = `${selectedCommandBase} ${commandArgs.join(" ")}`

    if (selectedCommandDetail.fixed){
      thisFinalCommand = selectedCommandDetail.cmd
    }
    
    setFinalCommand(thisFinalCommand)
  }

  const handleCommandClick = (c) => {
    const thisCommanDetail = getCommandDetailFromName(c)
    setSelectedCommandDetail(thisCommanDetail)
    setSelectedCommandBase(thisCommanDetail.cmd.split(" ")[0])
    setCommandName(c)
  }

  const evaluateCommandArgs = (argument) => {
    const evaluatedArg = commandArgEval[argument] ?? commandArgEval.DefaultArg
    return evaluatedArg
  }

  const selectDefaults = (commandName)=>{
    const thisCommanDetail = getCommandDetailFromName(commandName)
    const args = getCommandArgsFromDetail(thisCommanDetail)

    //Process Defaults
    let defaultArgs = args.map(a=> evaluateCommandArgs(a).eval ? evaluateCommandArgs(a).eval[0] : evaluateCommandArgs(a).list[0])
    if (commandName == 'Unban') defaultArgs = [banList[0]]

    setCommandArgs(defaultArgs)
  }

  const getCommandDetailFromName = (commandName) => {
    const thisCommandDetail = rconDetail.find(c=> c.name == commandName || c.cmd.split(" ")[0] == commandName)
    return thisCommandDetail
  }

  const getCommandArgsFromDetail = (commandDetail) => {
    const args = commandDetail.cmd.split(" ").length > 0 ? commandDetail.cmd.split(" ").slice(1) : []
    return args
  }

  const getCommandArgMap = (selectedCommandName) => {
    const selectedCommandDetail = getCommandDetailFromName(selectedCommandName)
    // console.log('cmdDetail:',selectedCommandDetail)
    if (selectedCommandDetail.fixed){
      setCommandArgMap([])
      return
    }
    const args = selectedCommandDetail.cmd.split(" ").length > 0 ? selectedCommandDetail.cmd.split(" ").slice(1) : []
    setCommandArgMap(args)
  }

  const handleSelect = (e, argName) =>{

    //Check Error
    const argEval = evaluateCommandArgs(argName)
    const isError = argEval.validator && !argEval.validator(e)
    setIsError(isError)

    //Map Eval
    const argIndex = commandArgMap.indexOf(argName)
    let newArgs = [...commandArgs]

    
    if (argEval && argEval.eval){
      const listIndex = argEval.list.indexOf(e)
      const evalue = argEval.eval[listIndex]
      newArgs[argIndex] = evalue || e
    } else {
      newArgs[argIndex] = e
    }
    setCommandArgs(newArgs)
    
  }

  const getValueFromArgs = (argName) => {
    const argEval = evaluateCommandArgs(argName)

    const argIndex = commandArgMap.indexOf(argName)
    const thisCommandArg = commandArgs[argIndex]
    if (argEval && argEval.eval) {
      const listIndex = argEval.eval.indexOf(thisCommandArg)
      const evalue = argEval.list[listIndex]
      return evalue
    } else {
      return thisCommandArg
    }
  }

  const mapArgToComponent = (arg) => {

    const thisEval = evaluateCommandArgs(arg)

    const listComponent = <SingleSelect value={getValueFromArgs(arg)} setValue={(e) => handleSelect(e, arg)} title={arg} data={thisEval.list} />
    const customEntryComponent = <TextField helperText={isError && thisEval.helperText} error={isError} onChange={(e) => handleSelect(e.target.value, arg)} label={`Custom ${arg}`} ></TextField>

    return (
      <Box>
        {thisEval.list && listComponent}
        {thisEval.allowCustom && customEntryComponent}
      </Box>
    )
    }

  const argsChildren = 
  <Box classes={{ "root": classes.arguments }} title="Arguments">
    <Box pb={2} className={classes.commandCheck}>{getCommandDetailFromName(commandName).desc}</Box>
    <Box pt={3}>
    {commandArgMap.map((arg, i) => {
      const argComponent = mapArgToComponent(arg)
      return (
        <FormControl key={arg + i} classes={{ "root": classes.formControl }}>
          {argComponent}
        </FormControl>
      )
    })
    }
    </Box>
    <Box pt={3} className={classes.commandCheckBox}>
      <Box className={classes.commandCheck} pt={3}>{`Command: ${finalCommand}`}</Box>
      <Button className={classes.executeButton} variant="contained" color="secondary" onClick={handleExecute}>{isProcessing ? 'Executing...' : 'Execute!'} </Button>
    </Box>
  </Box>



  return (
    <Box pt={1}>
      <CommandsSelector handleCommandClick={handleCommandClick} rconDetail={rconDetail} >
        {argsChildren}
      </CommandsSelector>
    </Box>
  )
}

function CommandsSelector({ handleCommandClick, rconDetail, children }) {
  const classes = useStyles()
  const uniqueCategories = rconDetail.map(c => c.category).filter((v, i, a) => a.indexOf(v) === i).filter(c => c != 'Other')

  const [category, setCategory] = React.useState(uniqueCategories[0]);
  const [tabValue, seTabValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    seTabValue(newValue);
    setCategory(uniqueCategories[newValue])
  };

  return (
    <Box className={classes.headerRow}>
      <Tabs variant='fullWidth' value={tabValue} onChange={handleChange} className={classes.tabs}>

        {uniqueCategories.map((c, i) => <Tab label={c} key={c+i} />)}

      </Tabs>
      <FormControl className={classes.commandBox}>

        <Box pt={3} classes={{ "root": classes.cmdBox }}>
          <CommandRadio setCommand={handleCommandClick} data={rconDetail.filter(c => c.category === category).map(c => c.name || c.cmd.split(" ")[0])}></CommandRadio>
          {children}
        </Box>

      </FormControl>
    </Box>
  )
}
